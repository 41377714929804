<template>
  <section>
    <div class="page-content-lightestblue">
      <div v-if="getContactList" class="page-content-lightestblue__container">
        <div class="heading-main py-4">
          <h2>Hersteller Service Informationen</h2>
        </div>
        <div class="content">
          <div class="contatc-info">
            <span>
              <p class="my-4">Wir haben für Sie eine Liste mit Hersteller-Service-Informationen zusammengestellt für den Fall, dass Probleme bei einem Produkt auftreten sollten.</p>
              <p class="my-4">
                Viele Antworten auf allgemeine Fragen können unter den FAQs auf den Webseiten der Hersteller gefunden werden. Für den Fall, dass Sie den Hersteller kontaktieren möchten, haben wir, wo
                möglich, Telefon-Nummern zu den Hotlines angegeben.
              </p>
              <p class="my-4">Bitte beachten Sie, dass weder PAYBACK noch die cadooz rewards GmbH für den Inhalt und die Richtigkeit von externen Webseiten verantwortlich sind.</p>
            </span>
          </div>
          <div class="contact-list">
            <h2 class="cursor-pointer">
              <a @click="showDetails(letter + '_alplabetId')" class="m-2" v-for="letter in createAlphabetList()" :key="letter">{{ letter }}</a>
            </h2>
            <div class="contact-list-detail">
              <div class="py-2" v-for="letter in createAlphabetList()" :key="letter">
                <div class="w-full">
                  <div class="module my-2 clearfix">
                    <div class="module-headline">
                      <h2 class="my-2" :id="letter + '_alplabetId'">{{ letter }}</h2>
                    </div>
                  </div>
                </div>
                <hr class="border border-dotted border-dark" />

                <div class="manufacturer-details grid grid-cols-6 gap-4 my-4">
                  <template v-for="manufacturer in getContactList" :key="manufacturer.id">
                    <div
                      id="manufacturerDetails"
                      class="col-span-2"
                      v-if="
                        manufacturer.name.charAt(0).toUpperCase() == letter ||
                        ((!isNaN(manufacturer.name.charAt(0).toUpperCase()) || !manufacturer.name.charAt(0).match('^[A-Za-z]+$')) && letter == '123')
                      "
                    >
                      <p v-if="manufacturer.name">
                        <strong>{{ manufacturer.name }}</strong>
                      </p>
                      <p v-if="manufacturer.website">
                        <label for="website">Website:</label>
                        <a :href="manufacturer.website" target="_blank">{{ manufacturer.website }}</a>
                      </p>
                      <p v-if="manufacturer.serviceWebsite">
                        <label for="service-website">Service-Website:</label>
                        <a :href="manufacturer.website" target="_blank">{{ manufacturer.serviceWebsite }}</a>
                      </p>
                      <p v-if="manufacturer.serviceEmail">
                        <label for="service-email">Service-Email:</label>
                        <a :href="'mailto:' + manufacturer.serviceEmail">{{ manufacturer.serviceEmail }}</a>
                      </p>
                      <p v-if="manufacturer.servicePhone">
                        <label for="service-rufnumer">Service-Rufnummer:</label>
                        <a :href="'tel:' + manufacturer.servicePhone">{{ manufacturer.servicePhone }}</a>
                        <span v-if="manufacturer.workHours">{{ manufacturer.workHours }}</span>
                        <span v-if="phoneCallPrice">{{ phoneCallPrice }}</span>
                      </p>
                      <p v-if="manufacturer.serviceFax">
                        <label for="service-fax-details">Service-Faxnummer:</label>
                        <span v-if="manufacturer.serviceFax">{{ manufacturer.serviceFax }}</span>
                        <span v-if="manufacturer.faxPrice">{{ manufacturer.faxPrice }}</span>
                      </p>
                      <p v-if="manufacturer.faq">
                        <label for="faq">FAQ:</label>
                        <a :href="manufacturer.faq" target="_blank">{{ manufacturer.faq }}</a>
                      </p>
                      <p v-if="manufacturer.address">
                        <label for="adress">Adresse:</label>
                        <span>{{ manufacturer.address }}</span>
                      </p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
  import { computed } from "vue";
  import { useStore } from "vuex";
  import AppImage from "@/components/Image/Image";

  export default {
    name: "Manufactures",
    components: {
      AppImage,
    },
    setup() {
      const store = useStore();

      store.dispatch("catalog/getContacts");

      const getContactList = computed(() => {
        return store.getters["catalog/getContacts"];
      });

      const createAlphabetList = () => {
        const alpha = Array.from(Array(26)).map((e, i) => i + 65);
        const alphabet = alpha.map((x) => String.fromCharCode(x));
        alphabet.push('123');
        return alphabet;
      };

      const showDetails = (id) => {
        const target = document.getElementById(id);
        const currentTargetVal = document.querySelector("html");
        if (currentTargetVal && target) {
          var isElement = target && target.nodeType === 1;
          if (isElement) {
            currentTargetVal.scrollTop = target.offsetTop;
          }
        }
      };
      return {
        getContactList,
        createAlphabetList,
        showDetails,
      };
    },
  };
</script>
<style>
  .contact-list a {
    color: rgb(0, 70, 170) !important;
    word-break: break-all;
  }
  .manufacturer-details label,
  .manufacturer-details span {
    margin: 0.8rem 0;
    display: flex;
  }
</style>
